import { Header, SpaceBetween,  StatusIndicator, Container, Box, ColumnLayout, ContentLayout } from "@amzn/awsui-components-react-v3";
import AppContext from "../../../context/AppContext";
import React from "react";
import {Button, Flashbar, Textarea} from "@amzn/awsui-components-react";
import { PageStage } from "../../../constants/constants";
import {Redirect} from "react-router-dom";

// <---------------------------- Page Header ----------------------------> //

export const PageHeader = ({ ...props }) => {
    const handleExecuteClick = () => {
        if (!props.executionPage.state.isValidJson) {
            props.executionPage.showAlert('Invalid JSON. Please use "print(json.dumps(execution_document))" to get valid JSON if you are copy-pasting from Secure AI Sandbox. `print(execution_document)` prints the dictionary, not the JSON representation.');
        } else {
            props.executionPage.uploadExecutionDocumentAndStartExecution();
        }
    };

    return (
        <Header
            variant="h1"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        onClick={handleExecuteClick}
                        variant="primary"
                        href={props.isValidJson ? (props.href || '') : undefined}
                    >
                        Execute
                    </Button>
                </SpaceBetween>
            }
        >
            Execute
        </Header>
    );
};


class ExecuteContent extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            generalData: {},
            generalDataLoaded: false,
            infoLoaded: false,
            showAlert: false,
            alert: "",
            showSuccess: false,
            success: "",
            templateName: "",
            templateVersion: "",
            gitfarmPackage: "",
            executionStructure: "",
            inputString: '',
            inputJson: null,
            isValidJson: true,
            jsonError: '',
            redirect: false,
            redirectLink: ""
        };

        this.getConfigData = this.getConfigData.bind(this);
        this.getGeneralData = this.getGeneralData.bind(this);
        this.setInputString = this.setInputString.bind(this);

    }

    setInputString(value) {
        this.setState({ inputString: value }, this.validateJson);
    }

    validateJson = () => {
        try {
            const parsedJson = JSON.parse(this.state.inputString);
            this.setState({
                inputJson: parsedJson,
                isValidJson: true,
                jsonError: ''
            });
        } catch (error) {
            this.setState({
                inputJson: null,
                isValidJson: false,
                jsonError: 'Invalid JSON. Please use `print(json.dumps(execution_document))" to get valid JSON.`'
            });
        }
    }

    getGeneralData() {
        const currentLocationPath = window.location.pathname.split("/");
        const gitfarmPackage = currentLocationPath[2];
        const templateName = currentLocationPath[3];
        const templateVersion = currentLocationPath[4];
        this.context.modsWorkflowManagementClient.getWorkflowTemplateVersionDetail(gitfarmPackage,templateName,templateVersion)
            .then((res) => {
                if (res.status === 500) {
                    this.showAlert("There is an internal server error. Please try again later.");
                } else if (res.status !== 200) {
                    this.showAlert(`Failed to retrieve workflow execution general information: ${res.data.message}`);
                }
                return res.data;
            })
            .then((data) => {
                this.setState({
                    generalData: data.workflowTemplateVersionInfo,
                    generalDataLoaded: true,
                    templateName: data.workflowTemplateVersionInfo.templateName,
                    templateVersion: data.workflowTemplateVersionInfo.templateVersion,
                    gitfarmPackage: data.workflowTemplateVersionInfo.gitfarmPackage
                })
            })
            .catch(console.log);
    };

    getConfigData() {
        const pathQueries = {
            gitfarm_package: this.state.gitfarmPackage,
            template_name: this.state.templateName,
            template_version: this.state.templateVersion
        }

        // Remove the feature of loading specific execution document.
        // See discussion in SIM: https://issues.amazon.com/issues/CARML-6826

        this.setState({infoLoaded: true});
    }

    uploadExecutionDocumentAndStartExecution = () => {
        if (!this.state.isValidJson) {
            return;
        }
        const inputJson = this.state.inputJson
        console.log(inputJson)
        const account = localStorage.getItem("modsAccount")
        const user = localStorage.getItem("userId");

        const gitfarmPackageName = this.state.gitfarmPackage
        const templateName = this.state.templateName
        const templateVersion = this.state.templateVersion
        const executionDocumentName = account+user
        const executionAwsAccountId = account
        this.context.modsWorkflowManagementClient.requestConcreteExecutionDocumentUploadLink(
            gitfarmPackageName,templateName,templateVersion,executionDocumentName)
            .then((res) => {
                if (res.status === 500) {
                    this.showAlert("There is an internal server error. Please try again later.");
                }
                return res;
            })
            .then(async (data) => {
                const presignedUrl = data.data.uploadPresignedUrl
                console.debug("Now trying to fetch presignedUrl:", presignedUrl)
                await fetch(presignedUrl, {
                    method: "PUT",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(inputJson)
                })
                    .then((res) => {
                        if (res.status === 200) {
                            this.showSuccess("Execution document uploaded.")
                        }
                    })

                this.context.modsWorkflowManagementClient.startWorkflowExecution(
                    gitfarmPackageName, templateName, templateVersion, executionDocumentName, data.data.executionDocumentVersion, executionAwsAccountId, user
                ).then((res) => {
                    if (res.status === 200) {
                        this.showSuccess("Successfully start an execution.");

                        this.setState({
                            redirect: true,
                            redirectLink: "/workflow_executions/"+ res.data.workflowExecutionInfo.executionId,
                        })
                    } else {
                        this.showAlert("There is an internal server error when starting workflow execution. Please try again later.");
                    }
                })
            })

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.generalDataLoaded && !this.state.generalDataLoaded) {
                console.debug("Page fully loaded and execution detail never being loaded. Retrieve execution detail from remote.");
                this.getGeneralData();
            }
        }
        if ( PageStage.FULLY_LOADED === this.context.pageStage ) {
            if (!prevState.infoLoaded && !this.state.infoLoaded && this.state.templateName !== "") {
                console.debug("Page fully loaded and account never being loaded. Retrieve account list from remote.");
                this.getConfigData();
            }
        }
    }

    showAlert(message) {
        this.setState({
            showAlert: true,
            alert: message,
        });
    }

    showSuccess(message) {
        this.setState({
            showSuccess: true,
            success: message,
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (
            <div>
                {this.state.showAlert || this.state.showSuccess ? (
                    <div id="flashbar">
                        <Flashbar
                            items={[
                                {
                                    type: this.state.showAlert ? "error" : "success",
                                    content: this.state.showAlert ? this.state.alert : this.state.success,
                                    dismissible: true,
                                    // dismiss: this.clearFlashbar,
                                },
                            ]}
                        />
                    </div>
                ) : null}
                <ContentLayout
                    header={
                        <PageHeader
                            executionPage={this}
                        />
                    }
                >
            <SpaceBetween size="l">
                <Container header={<Header variant="h2">General Information</Header>}>
                    <ColumnLayout columns={4} variant="text-grid">
                        <div>
                            <Box variant="awsui-key-label">Workflow Definition Name</Box>
                            <div>{this.state.generalData.templateName}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Workflow Definition Creator</Box>
                            <div>{this.state.generalData.templateAuthor}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Workflow Definition Creation Time</Box>
                            <div>{new Date(this.state.generalData.createDate * 1000).toLocaleString()}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Workflow Status</Box>
                            <StatusIndicator type="success">{this.state.generalData.templateStatus}</StatusIndicator>
                        </div>
                    </ColumnLayout>
                </Container>
                <Container header={<Header variant="h2">Input Execution Document</Header>}>
                    <p>Please paste your execution document below:</p>
                    <Textarea
                        onChange={({detail}) => this.setInputString(detail.value)}
                        rows={this.state.executionStructure.split(/\r\n|\r|\n/).length}
                        value={this.state.inputString || this.state.executionStructure}
                        readOnly
                    />
                </Container>
            </SpaceBetween>
                </ContentLayout>
            </div>
        );
    }
}

export default ExecuteContent;
