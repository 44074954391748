import {
    Box,
    CollectionPreferences,
    Header,
    Pagination,
    SpaceBetween, Table,
    TextFilter
} from "@amzn/awsui-components-react-v3/polaris";
import React, {useMemo, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    collectionPreferencesProps,
    ColumnDefinitions,
    getMatchesCountText,
    paginationLabels
} from "./AMLETTableDefinitions";

const AMLETExperimentResults = ({searchData, isLoading, isYourExperiments}) => {
    const [localSearchData, setLocalSearchData] = useState([]);

    // Reset data when searchData changes
    React.useEffect(() => {
        setLocalSearchData([]);
        setTimeout(() => {
            setLocalSearchData(searchData || []);
        }, 1);
    }, [searchData]);

    const EmptyState = ({ title, subtitle, action }) => {
        return (
            <Box textAlign="center" color="inherit">
                <Box variant="strong" textAlign="center" color="inherit">
                    {title}
                </Box>
                <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                    {subtitle}
                </Box>
                {action}
            </Box>
        );
    }

    const [preferences, setPreferences] = useState({
        pageSize: 10,
        contentDisplay: ColumnDefinitions.map(({ id, header }) => ({
            id,
            label: header,
            visible: true,
        })),
    });
    const getSafeValue = (value) => {
        if (value === null || value === undefined || value === '' || value === ' ') {
            return '-';
        }
        return value.toString();
    };

    const stringifiedSearchData = useMemo(() => {
        if (!localSearchData) return [];

        return localSearchData.map(item => {
            const newItem = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    newItem[key] = getSafeValue(item[key]);
                }
            }
            return newItem;
        });
    }, [localSearchData]);

    const availableColumns = useMemo(() => {
        var ret = ColumnDefinitions.filter((column) =>
            searchData?.some((item) => item.hasOwnProperty(column.id))
        );

        if (isYourExperiments) {

            const dashboardVisibilityColumn = ColumnDefinitions.find(column => column.id === "dashboardVisibility");
            if (dashboardVisibilityColumn && !ret.includes(dashboardVisibilityColumn)) {
                ret.push(dashboardVisibilityColumn);
            }

            const updatedAtColumn = ColumnDefinitions[ColumnDefinitions.length - 1];
            if (!ret.includes(updatedAtColumn)) {
                ret.push(updatedAtColumn);
            }
        }
        return ret;
    }, [searchData, isYourExperiments]);

    const getDefaultSortingColumn = (availableColumns) => {
        let sortingCol = {
            sortingColumn: { sortingField: ColumnDefinitions[1].id, sortingComparator: ColumnDefinitions[1].sortingComparator },
            isDescending: false
        };

        if (!availableColumns) {
            console.warn('No availableColumns provided, using default fallback');
            return sortingCol;
        }

        const rocAucColumn = availableColumns.find(col => col.id === 'roc_auc' || col.id === 'auc');

        if (rocAucColumn) {
            sortingCol = {
                sortingColumn: {sortingField: rocAucColumn.sortingColumn, sortingComparator: rocAucColumn.sortingComparator},
                isDescending: true
            };
            return sortingCol;
        }

        const negLogLossColumn = availableColumns.find(col => col.id === 'neg_log_loss');

        if (negLogLossColumn) {
            sortingCol = {
                sortingColumn: {sortingField: negLogLossColumn.sortingColumn, sortingComparator: negLogLossColumn.sortingComparator},
                isDescending: false
            };
            return sortingCol;
        }

        const negRMSEColumn = availableColumns.find(col => col.id === 'neg_root_mean_squared_error');

        if (negRMSEColumn) {
            sortingCol = {
                sortingColumn: {sortingField: negRMSEColumn.sortingColumn, sortingComparator: negRMSEColumn.sortingComparator},
                isDescending: false
            };
            return sortingCol;
        }

        return sortingCol;
    };

    // State for sorting configuration
    const [sortingConfig, setSortingConfig] = useState(getDefaultSortingColumn(availableColumns));

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        stringifiedSearchData,
        {
            filtering: {},
            pagination: { pageSize: preferences.pageSize },
            sorting: {
                defaultState: sortingConfig
            },
            selection: {},
        }
    );

    // Update sorting config when data or available columns change
    React.useEffect(() => {
        const newSortingConfig = getDefaultSortingColumn(availableColumns);
        setSortingConfig(newSortingConfig);
        actions.setSorting(newSortingConfig);
    }, [searchData, availableColumns]);

    React.useEffect(() => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            contentDisplay: availableColumns.map(({ id, header }) => ({
                id,
                label: header,
                visible: prevPreferences.contentDisplay.find((cd) => cd.id === id)?.visible || true,
            })),
        }));
    }, [availableColumns]);

    return (
        <Table
            {...collectionProps}
            ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.modelName
            }}
            columnDefinitions={availableColumns.map((column) => ({
                ...column,
                visible: preferences.contentDisplay.find((cd) => cd.id === column.id)?.visible,
            }))}
            columnDisplay={preferences.contentDisplay.map(({ id, visible }) => ({ id, visible }))}
            enableKeyboardNavigation
            items={items?items:[]}
            trackBy="modelName"
            resizableColumns
            stickyHeader
            stripedRows
            wrapLines
            loading={isLoading}
            loadingText="Loading Experiments"
            empty={
                items.length === 0 ? (
                    <EmptyState
                        title="No Experiments"
                        subtitle="There are no experiments to display."
                    />
                ) : (
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No Experiments</b>
                        </SpaceBetween>
                    </Box>
                )
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(items.length)}
                    filteringAriaLabel="Filter instances"
                    filteringPlaceholder="Filter instances"
                    filteringClearAriaLabel="Clear"
                />
            }
            header={
                <Header
                    counter={
                        items.length
                            ? " ("+items.length+")"
                            : " (0)"
                    }
                >
                    Experiments
                </Header>
            }
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={({ detail }) => setPreferences(detail)}
                />
            }
        />
    )
};

export default AMLETExperimentResults;