import {
    Button,
    Container,
    Header,
    Select,
    SpaceBetween,
    FormField,
    Grid, DateRangePicker,
} from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import AMLETExperimentResults from "./AMLETSearchResults";
import AppContext from "../../context/AppContext";
import { GetDatasetNames, GetModelNames, SearchModelsResult } from "./AMLETGremlinQueryHelper";
import { Input } from "@amzn/awsui-components-react";
import { supportedModelTypes } from "../../constants/amlet_constants";

const AMLETSearchContainer = () => {

    const { modsWorkflowManagementClient } = React.useContext(AppContext);

    const [selectedModelName, setSelectedModelName] = React.useState();
    const [selectedModelType, setSelectedModelType] = React.useState({
        label: "All_Types",
        value: "All_Types"
    });
    const [selectedModelAuthor, setSelectedModelAuthor] = React.useState('');
    const [selectedDataset, setSelectedDataset] = React.useState();

    const [dateValue, setDateValue] = React.useState(undefined);
    const [isSearching, setIsSearching] = React.useState(false);
    const [searchData, setSearchData] = React.useState([]);

    const [modelTypes, setModelTypes] = React.useState<string[]>([]);
    const [datasets, setDatasets] = React.useState<string[]>([]);
    const [modelNames, setModelNames] = React.useState<string[]>([]);

    const [formErrors, setFormErrors] = React.useState({
        modelType: "",
        modelNameOrDataset: "",
    });


    const [isPageLoaded, setIsPageLoaded] = React.useState(false);

    // Make sure page is loaded before
    React.useEffect(() => {
        const handleLoad = () => {
            setIsPageLoaded(true);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    // Fetch Models and Datasets that are onboarded to AMLET
    // for populating in the search form after the page is loaded
    React.useEffect(() => {
        if (isPageLoaded) {
            if (!modsWorkflowManagementClient) {
                console.error('modsWorkflowManagementClient is undefined');
                return;
            }
            GetModelNames(modsWorkflowManagementClient)
                .then(data => setModelNames(data))
                .catch(err => {
                    console.error("Error fetching model names:", err);
                    setModelNames([]);
                });
            GetDatasetNames(modsWorkflowManagementClient)
                .then(data => setDatasets(data))
                .catch(err => {
                    console.error("Error fetching dataset names:", err);
                    setDatasets([]);
                });

            setModelTypes(supportedModelTypes);
        }
    }, [modsWorkflowManagementClient, isPageLoaded]);

    const handleSearch = () => {

        // Validate form fields
        const errors = {
            modelNameOrDataset: validateModelNameOrDataset(selectedModelName, selectedDataset),
            modelType: validateField(selectedModelType, "Model type is required"),
        };
        setFormErrors(errors);
        const isValid = Object.values(errors).every(error => error === "");

        if(isValid){
            setIsSearching(true);

            const { startDate, endDate } = calculateDateRange(dateValue);

            const searchParams = {
                modelType: selectedModelType?.value,
                modelName: selectedModelName?.value,
                dataset: selectedDataset?.value,
                modelAuthor: selectedModelAuthor,
                startDate: startDate,
                endDate: endDate
            }

            SearchModelsResult(modsWorkflowManagementClient,searchParams)
                .then((res) => {
                    setSearchData(res)
                    setIsSearching(false);
                }).catch((error) => {
                console.error(
                    "Error searching for data with parameters:",
                    JSON.stringify(searchParams, null, 2),
                    "\nError:",
                    error
                );
                setIsSearching(false);
                });
        }
    };

    const calculateDateRange = (dateValue) => {
        if (!dateValue) return { startDate: undefined, endDate: undefined };

        const now = new Date();
        let startDate, endDate;

        if (dateValue.type === "absolute") {
            return { startDate: dateValue.startDate, endDate: dateValue.endDate };
        }

        if (dateValue.type === "relative") {
            endDate = now.toISOString();
            startDate = new Date(now);

            const timeUnits = {
                second: (date, amount) => date.setSeconds(date.getSeconds() - amount),
                minute: (date, amount) => date.setMinutes(date.getMinutes() - amount),
                hour: (date, amount) => date.setHours(date.getHours() - amount),
                day: (date, amount) => date.setDate(date.getDate() - amount),
                week: (date, amount) => date.setDate(date.getDate() - (7 * amount)),
                month: (date, amount) => date.setMonth(date.getMonth() - amount),
                year: (date, amount) => date.setFullYear(date.getFullYear() - amount),
            };

            if (timeUnits[dateValue.unit]) {
                timeUnits[dateValue.unit](startDate, dateValue.amount);
            } else {
                console.error('Unsupported time unit:', dateValue.unit);
                return { startDate: undefined, endDate: undefined };
            }

            return { startDate: startDate.toISOString(), endDate };
        }

        return { startDate: undefined, endDate: undefined };
    };

    const validateField = (value, errorMessage) => {
        return value ? "" : errorMessage;
    };

    const validateModelNameOrDataset = (modelName, dataset) => {
        if (!modelName && !dataset) {
            return "Either Model name or Dataset is required";
        }
        if (modelName && dataset) {
            return "Please select either Model name or Dataset, not both";
        }
        return "";
    };

    return (
        <div>
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button onClick={handleSearch} disabled={isSearching}>
                                    {isSearching ? 'Searching...' : 'Search'}
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        Search
                    </Header>
                }
            >

                <Grid
                    gridDefinition={[
                        {colspan: {default: 3, xxs: 3}},
                        {colspan: {default: 3, xxs: 3}},
                        {colspan: {default: 3, xxs: 3}},
                        {colspan: {default: 3, xxs: 3}},
                        {colspan: {default: 3, xxs: 9}},
                    ]}
                >
                    <FormField label={"Type"}
                               errorText={formErrors.modelType}>
                        <Select
                            selectedOption={selectedModelType}
                            onChange={({detail}) =>
                                setSelectedModelType(detail.selectedOption)
                            }
                            options={
                                modelTypes.map((modelType) => ({
                                    label: modelType,
                                    value: modelType,
                                    disabled: true
                                }))
                            }
                            ariaRequired
                            autoFocus
                            virtualScroll
                            filteringType="auto"
                        />
                    </FormField>
                    <FormField label={"Dataset"} errorText={formErrors.modelNameOrDataset}>
                        <Select
                            selectedOption={selectedDataset}
                            onChange={({detail}) =>{
                                setSelectedDataset(detail.selectedOption);
                                setSelectedModelName(undefined);
                            }}
                            options={
                                datasets.map((dataset) => ({
                                    label: dataset.split(":")[3],
                                    value: dataset
                                }))
                            }
                            ariaRequired
                            autoFocus
                            virtualScroll
                            filteringType="auto"
                        />
                    </FormField>
                    <FormField label={"Model Name"} errorText={formErrors.modelNameOrDataset}>
                        <Select
                            selectedOption={selectedModelName}
                            onChange={({detail}) => {
                                setSelectedModelName(detail.selectedOption);
                                setSelectedDataset(undefined);
                            }}
                            options={
                                modelNames.map((modelName) => ({
                                    label: modelName.split(":")[3],
                                    value: modelName
                                }))
                            }
                            ariaRequired
                            autoFocus
                            virtualScroll
                            filteringType="auto"
                        />
                    </FormField>
                    <FormField label={"Model Author (optional)"}>
                        <Input
                            value={selectedModelAuthor}
                            onChange={({detail}) =>
                                setSelectedModelAuthor(detail.value)
                            }
                            placeholder="Enter model author"
                        />
                    </FormField>
                    <FormField label={"Date Range (optional)"}>
                        <DateRangePicker
                            onChange={({detail}) => setDateValue(detail.value)}
                            value={dateValue}
                            relativeOptions={[
                                {
                                    key: "previous-5-minutes",
                                    amount: 5,
                                    unit: "minute",
                                    type: "relative"
                                },
                                {
                                    key: "previous-30-minutes",
                                    amount: 30,
                                    unit: "minute",
                                    type: "relative"
                                },
                                {
                                    key: "previous-1-hour",
                                    amount: 1,
                                    unit: "hour",
                                    type: "relative"
                                },
                                {
                                    key: "previous-6-hours",
                                    amount: 6,
                                    unit: "hour",
                                    type: "relative"
                                }
                            ]}
                            isValidRange={range => {
                                if (range.type === "absolute") {
                                    const [
                                        startDateWithoutTime
                                    ] = range.startDate.split("T");
                                    const [
                                        endDateWithoutTime
                                    ] = range.endDate.split("T");
                                    if (
                                        !startDateWithoutTime ||
                                        !endDateWithoutTime
                                    ) {
                                        return {
                                            valid: false,
                                            errorMessage:
                                                "The selected date range is incomplete. Select a start and end date for the date range."
                                        };
                                    }
                                    if (
                                        new Date(range.startDate) -
                                        new Date(range.endDate) >
                                        0
                                    ) {
                                        return {
                                            valid: false,
                                            errorMessage:
                                                "The selected date range is invalid. The start date must be before the end date."
                                        };
                                    }
                                }
                                return {valid: true};
                            }}
                            i18nStrings={{
                                todayAriaLabel: "Today",
                                nextMonthAriaLabel: "Next month",
                                previousMonthAriaLabel: "Previous month",
                                customRelativeRangeDurationLabel: "Duration",
                                customRelativeRangeDurationPlaceholder: "Enter duration",
                                customRelativeRangeOptionLabel: "Custom range",
                                customRelativeRangeOptionDescription: "Set a custom range in the past",
                                customRelativeRangeUnitLabel: "Unit of time",
                                formatRelativeRange: /* istanbul ignore next */ (e) => {
                                    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                    return `Last ${e.amount} ${t}`;
                                },
                                // istanbul ignore next
                                formatUnit: /* istanbul ignore next */ (e, t) => (1 === t ? e : `${e}s`),
                                dateTimeConstraintText: "Range is 6 to 30 days. For date, use YYYY/MM/DD. For time, use 24 hr format.",
                                relativeModeTitle: "Relative range",
                                absoluteModeTitle: "Absolute range",
                                relativeRangeSelectionHeading: "Choose a range",
                                startDateLabel: "Start date",
                                endDateLabel: "End date",
                                startTimeLabel: "Start time",
                                endTimeLabel: "End time",
                                clearButtonLabel: "Clear and dismiss",
                                cancelButtonLabel: "Cancel",
                                applyButtonLabel: "Apply",
                            }}
                            placeholder="Filter by a date and time range"
                        />
                    </FormField>
                </Grid>

            </Container>
            <br></br>
            <AMLETExperimentResults searchData={searchData} isLoading={isSearching} isYourExperiments={false}></AMLETExperimentResults>
        </div>
    )
        ;
};

export default AMLETSearchContainer;